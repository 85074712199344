@import "../../../responsive/breakpoints";
@import "../../../abstract/variables";
// entire file global css
.card_heading {
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  color: $text-p-color;
  padding-top: 15px !important;
  h2 {
    font-size: 15px;
    text-align: left;
    color: $text-p-color;
    text-transform: uppercase;
    margin-bottom: 10px !important;
    position: relative;
    margin-left: 20px !important;
    font-weight: 500 !important;
    &::before {
      content: "";
      position: absolute;
      width: 3px;
      height: 83%;
      background: $text-menu-active-color;
      top: 3px;
      left: -8px;
      bottom: 0;
    }
  }
  p {
    font-size: 16px;
    color: $text-default-color;
    span {
      color: red !important;
    }
  }
}
.ss-trade_homPage_wrapper {
  transition: all 0.3s ease-in-out;

  .ss-trade_homPage_important_notice_section {
    width: 100%;

    img {
      width: 100%;
      height: 200px;
      margin-bottom: 10px;
    }
  }
  .ss-trade_section_title {
    h2 {
      font-size: 20px;
      color: $text-p-color;
      transition: all 0.3s ease-in-out;
    }
  }
  .importantLinks {
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: $bg-white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    margin-top: 15px;

    @include breakpoints(portraitTablet) {
      flex-direction: column;
      padding: 10px 0;
    }

    span {
      color: $text-default-color;
      display: block !important;
      @include breakpoints(portraitTablet) {
        display: none !important;
      }
    }

    .socialLinksBox {
      display: inline;
      @include breakpoints(largeMobile) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      .socialLinks {
        display: inline;
        .refWhatsappButton {
          background-color: #28a745;
          padding: 5px 10px;
          margin: 3px 5px;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 15px;
          cursor: pointer;
        }

        .refFacebookButton {
          background-color: #3b5998;
          padding: 5px 10px;
          margin: 3px 5px;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 15px;
          cursor: pointer;
        }

        .refTelegramButton {
          background-color: #229ed9;
          padding: 5px 10px;
          margin: 3px 5px;
          border: none;
          border-radius: 5px;
          color: #fff;
          font-size: 15px;
          cursor: pointer;
        }

        .refferLink {
          border: none;
          border-radius: 5px;
          padding: 5px 10px;
          margin: 3px 5px;
          background-color: #007bff;
          color: #fff;
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
  }
  .first_row {
    display: flex;
    @include breakpoints(largeLaptop) {
      display: inherit !important;
    }
    .ss-trade_dash_content_item {
      width: 100%;
      // @include breakpoints(largeLaptop) {
      //   width: 100%;
      // }
      .ss-trade_dash_content {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        margin-top: 20px;
        grid-row-gap: 15px;
        grid-column-gap: 15px;
        // height: 140px;
        // margin-right: 10px;
        @include breakpoints(largeLaptop) {
          grid-template-columns: repeat(2, 1fr);
          // row-gap: 15px;
        }
        @include breakpoints(smLaptop) {
          grid-template-columns: repeat(2, 1fr);
          // row-gap: 15px;
        }
        @include breakpoints(4kdevice) {
          grid-template-columns: repeat(4, 1fr);
          row-gap: 15px;
        }
        @include breakpoints(portraitTablet) {
          grid-template-columns: repeat(1, 1fr);
          row-gap: 15px;
        }

        .ss-trade_dash_card_wrapper {
          background-image: url("../../../../assets/card.jpeg");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding: 44px 22px;
          background-color: $bg-white;
          display: grid;
          align-items: center;
          transition: all 0.3s ease-in-out;
          border-right: $border-1;
          border-radius: 10px;
          .ss-trade_content {
            position: relative;
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            transition: all 0.3s ease-in-out;
            justify-content: space-between;
            .ss-trade_widget_info {
              p {
                font-size: 32px;
                color: $text-p-color;
                // white-space: nowrap;
                -webkit-text-stroke: 0.1px black;
                color: white;
                text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
                  -1px 1px 0 #000, 1px 1px 0 #000;
              }
              h2 {
                font-size: 24px;
                color: #fff;
                margin-bottom: 10px !important;
              }
              a {
                color: $text-black;
                font-size: 13px;
                text-transform: capitalize;
                font-weight: 500;
                &:hover {
                  color: $text-menu-active-color;
                }
              }
            }
            .ss-trade_widget_icon {
              margin-right: 20px;
              // background-color: red;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              padding: 0px;
              @include breakpoints(largeLaptop) {
                // order: 2;
                margin-right: 0px;
              }
              svg {
                color: #fff;
                font-size: 30px;
                border-radius: 50px;
                padding: 10px;
              }
            }
          }
        }
      }
      .ss-trade_dashboard_chart {
        margin-top: 20px !important;
        // margin-right: 10px !important;
        padding-top: 10px !important;
        @include breakpoints(largeLaptop) {
          margin-right: 0px !important;
        }
      }
      .ss-trade_autopool_button_section {
        text-align: center;
        button {
          margin-top: 10px;
          width: 80%;
          padding: 10px 8px;
          background: $bg-thin-green;
          box-shadow: $box-shadow-sidebar-menu;
          color: #fff;
          border: none;
          font-size: 14px;
          font-weight: 500;
          text-transform: capitalize;
          border-radius: 3px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
          @include breakpoints(portraitTablet) {
            width: 100%;
          }
        }
      }
      // refer copy link
      .ss-trade_link_copy_section {
        margin-top: 20px;
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: $bg-white;
        form {
          display: flex;
          align-items: center;
          transition: all 0.3s ease-in-out;
          @include breakpoints(smLaptop) {
            display: inherit;
          }
          .ss-trade_input_field {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 25px 25px;

            @include breakpoints(smLaptop) {
              width: auto;
            }

            @include breakpoints(largeMobile) {
              display: inherit;
              text-align: center;
              padding: 25px 10px;
            }
            .input_group {
              width: 100%;
              Input {
                font-size: 15px;
                padding: 18px 10px;
                width: 100%;
                outline: none;
                border-left: $border-4;
                border-top: $border-4;
                border-bottom: $border-4;
                border-right: none;
                color: $text-gray;
                background-color: $bg-body;
                border-radius: 5px;
                transition: all 0.3s ease-in-out;
                @include breakpoints(largeMobile) {
                  width: 90%;
                }
              }
            }
            button {
              font-size: 15px;
              padding: 19px 8px;
              outline: none;
              width: 200px;
              border: none !important;
              text-transform: capitalize;
              background-color: $bg-thin-green;
              color: #fff;
              transition: all 0.3s ease-in-out;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
              @include breakpoints(largeMobile) {
                margin-top: 20px;
                width: 100% !important;
                border-radius: 5px !important;
              }
            }
          }
        }
      }
    }
    // .recent_deposit {
    //   width: 25%;
    //   height: auto;
    //   margin-top: 20px;
    //   // box-shadow: $box-shadow-1;
    //   margin-left: 10px;
    //   @include breakpoints(largeLaptop) {
    //     width: 100%;
    //     margin-left: 0px;
    //     height: 300px;
    //     overflow: auto;
    //   }
    //   .ss-trade_recent_deposit_card {
    //     .ss-trade_recent_deposit {
    //       .ss-trade_recent_deposit_item {
    //         padding: 10px;
    //         .ss-trade_recent_deposit_ul {
    //           li {
    //             display: flex;
    //             justify-content: space-between;
    //             padding: 10px 0;
    //             .deposit_image {
    //               display: flex;
    //               align-items: center;
    //               .icon {
    //                 background: rgba(28, 213, 174, 0.2) !important;
    //                 align-items: center;
    //                 border-radius: 5px;
    //                 display: flex;
    //                 justify-content: center;
    //                 padding: 10px;
    //                 svg {
    //                   color: #38cab3 !important;
    //                   font-size: 14px;
    //                 }
    //               }
    //               .left_name {
    //                 margin-left: 10px !important;
    //                 .name {
    //                   font-size: 13px;
    //                   color: $text-dark-p-color;
    //                 }
    //                 .date {
    //                   font-size: 12px;
    //                   color: $text-gray-p-color;
    //                 }
    //               }
    //             }
    //             .deposit_content {
    //               text-align: end;
    //               color: #38cab3 !important;
    //               .status {
    //                 font-size: 11px;
    //               }
    //               .amount {
    //                 font-size: 12px;
    //                 font-weight: 600 !important;
    //               }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
  .second_row {
    display: flex;
    @include breakpoints(largeLaptop) {
      flex-wrap: wrap;
    }
    .recent_fund_transfer {
      width: 25%;
      height: auto;
      margin-top: 20px;
      box-shadow: $box-shadow-1;
      margin-right: 10px;
      background: $bg-white;
      @include breakpoints(largeLaptop) {
        width: 100%;
        margin-right: 0px;
        order: 2;
      }
      .ss-trade_recent_fund_transfer_card {
        .ss-trade_recent_fund_transfer {
          .ss-trade_recent_fund_transfer_item {
            padding: 10px;
            .ss-trade_recent_fund_transfer_ul {
              li {
                display: flex;
                justify-content: space-between;
                padding: 10px 0;
                .fund_transfer_image {
                  display: flex;
                  align-items: center;
                  .icon {
                    background: $bg-thin-blue !important;
                    align-items: center;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    padding: 10px;
                    svg {
                      color: $text-primary !important;
                      font-size: 14px;
                    }
                  }
                  .left_name {
                    margin-left: 10px !important;
                    .name {
                      font-size: 13px;
                      color: $text-dark-p-color;
                    }
                    .date {
                      font-size: 12px;
                      color: $text-gray-p-color;
                    }
                  }
                }
                .fund_transfer_content {
                  text-align: end;
                  color: $text-primary !important;
                  .status {
                    font-size: 11px;
                  }
                  .amount {
                    font-size: 12px;
                    font-weight: 600 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .ss-trade_topup_card {
      margin-top: 20px;
      width: 75%;
      margin-left: 10px;
      min-height: 250px;
      @include breakpoints(largeLaptop) {
        width: 100%;
        margin-left: 0px;
      }
      // @include breakpoints(xtraLargeLaptop) {
      //   width: 50%;
      //   margin-right: 22px;
      // }
      // @include breakpoints(laptop) {
      //   width: auto !important;
      //   margin-right: 0px;
      //   margin-bottom: 30px;
      // }
    }
  }

  // my popup
  .ss-trade_my_topup {
    margin-top: 30px;
    margin-bottom: 20px;
    .ss-trade_my_topup_card {
      margin-top: 0px;
      min-height: 250px;
    }
  }
  // auto pool and level income
  .ss-trade_auto_pool_level_income {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    @include breakpoints(laptop) {
      display: inherit;
    }
    .ss-trade_auto_pool_card {
      margin-top: 0px;
      width: 50%;
      margin-right: 22px;
      min-height: 250px;
      @include breakpoints(laptop) {
        width: auto !important;
        margin-right: 0px;
        margin-bottom: 30px;
      }
    }
    .ss-trade_level_card {
      margin-top: 0px;
      width: 50%;
      // margin-left: 22px;
      min-height: 250px;
      @include breakpoints(laptop) {
        width: auto !important;
      }
    }
  }
  .custom_modal {
    height: auto !important;
    width: 40% !important;
    min-height: 60% !important;
    @include breakpoints(portraitTablet) {
      width: 70% !important;
    }
    @include breakpoints(largeMiniMobile) {
      width: 85% !important;
    }
    .ss-trade_commol_modal_field {
      img {
        width: 100% !important;
      }
    }
  }
}

.apexcharts-tooltip {
  background: #f3f3f3;
  color: orange;
}
