@import "../../../responsive/breakpoints";
.ss-trade_add_funds_page_wrapper {
  margin-top: 10px;
  .ss-trade_add_funds_card {
    padding: 25px;
    min-height: 250px;
    .ss-trade_section_title {
      text-align: start;
      margin-bottom: 20px;
      h2 {
        font-size: 20px;
        color: $text-default-color;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          font-size: 20px;
        }
      }
    }
    .ss-trade_add_funds_page_content {
      form {
        .ss-trade_input_field {
          .right {
            margin-right: 25px;
          }
          label {
            font-size: 15px;
            font-weight: 600;
            color: $text-default-color;
            transition: all 0.3s ease-in-out;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
          .ss-trade_input {
            width: 100%;
            padding: 10px 8px;
            color: $text-default-color;
            font-size: 15px;
            border: $border-header;
            outline: none;
            margin: 10px 0;
            border-radius: 8px;
            background: $bg-body;
          }
          .notice {
            p {
              color: red;
              font-size: 15px;
              font-weight: 600;
              @include breakpoints(tablet) {
                font-size: 12px;
              }
            }
          }
        }
        .wallet_icon_title {
          margin-top: 20px;
          p {
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            margin-bottom: 10px !important;
            color: $text-default-color;
            transition: all 0.3s ease-in-out;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
        }
        .wallet_icon {
          margin: 20px 0 !important;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .input_wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #e4e3e369;
            margin: 5px;
            border-radius: 5px;
            position: relative;
            input[type="radio" i] {
              width: 25px;
              height: 25px;
              position: absolute;
              left: 14px;
              top: 30px;
              @include breakpoints(tablet) {
                width: 15px;
                height: 15px;
              }
            }
            label {
              padding: 13px;
              width: 100%;
              padding-left: 48px;
              user-select: none;
              @include breakpoints(tablet) {
                padding-left: 35px;
              }
              .icon {
                img {
                  width: 30px;
                  height: 30px;
                  margin-bottom: 10px;
                  @include breakpoints(tablet) {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
              .name {
                font-size: 15px;
                text-transform: capitalize;
                font-weight: 600;
                margin-top: -5px;
                color: $text-default-color;
                transition: all 0.3s ease-in-out;
                @include breakpoints(tablet) {
                  font-size: 13px;
                }
              }
            }
          }
        }
        .submit_button {
          .submit_btn {
            background: $bg-blue-linear-gradient;
            box-shadow: $box-shadow-sidebar-menu;
            padding: 10px 12px;
            width: 100px;
            border-radius: 5px;
            border: none;
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            color: #fff;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              opacity: 0.8;
            }
            @include breakpoints(portraitTablet) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
