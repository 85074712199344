.core-service-container {
  background-color: #fff !important;
  background-image: url("../../../assets/banner3rd.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0 120px 0;

  @include breakpoints(tablet) {
    padding: 0px 0 60px 0;
  }
}

.core-service-container .dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-bottom: 20px;
}

.core-service-container .dots span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.core-service-container .dots span:first-child {
  background-color: #00a8ff;
}

.core-service-container .dots span:nth-child(2) {
  background-color: #9c88ffff;
}

.core-service-container .dots span:nth-child(3) {
  background-color: #fbc531ff;
}

.core-service-container .dots span:nth-child(4) {
  background-color: #4cd137ff;
}

.core-service-container .dots span:nth-child(5) {
  background-color: #487eb0ff;
}

.core-service-container .dots span:nth-child(6) {
  background-color: #e84118ff;
}

.core-service-container .dots span:nth-child(7) {
  background-color: #8c7ae6ff;
}
.text-content {
  position: relative;
  z-index: 1;
  padding-bottom: 80px;
  text-align: center;

  @include breakpoints(tablet) {
    padding-bottom: 50px;
  }
  h2 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  p {
    line-height: 2;
    font-size: 16px;
  }
}
.grid-container {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  // @include breakpoints(largeLaptop) {
  //   grid-template-columns: repeat(2, 1fr); // For large laptops and similar
  // }

  // @include breakpoints(tablet) {
  //   grid-template-columns: 1fr; // For tablets
  // }

  // @include breakpoints(mobile) {
  //   grid-template-columns: 1fr; // For mobile
  //   padding: 20px;
  // }

  // // Center alignment for all screen sizes
  // justify-items: center;

  .service_single_content {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 100vw;
    height: 200px;
    margin: 0 auto;
    flex: 4;
    flex-wrap: wrap;
    z-index: 1;
    padding: 70px 46px 55px;
    background: #1a1a1d;
    box-shadow: 0 24px 65px 0 rgb(0 0 0 / 64%);
    margin-bottom: 30px;

    @include breakpoints(tablet) {
      flex: 1;
      width: 100%;
      padding: 40px;
      background: #1a1a1d;
      box-shadow: 0 24px 65px 0 rgba(0, 0, 0, 0.64);
      margin-bottom: 0px;
    }

    .service_icon {
      margin-bottom: 20px;
      min-height: 56px;
    }
    & h2 {
      margin-bottom: 15px;
      font-size: 24px;
      color: #fff;
      line-height: 1.4;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    & p {
      p {
        line-height: 1.9;
        color: rgba(255, 255, 255, 0.7);
        font-size: 15px;
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
