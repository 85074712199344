.trusted_container {
  background-image: url("../../../assets/banner2n.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
  height: 770px;

  @include breakpoints(tablet) {
    padding: 60px 0 60px 0;
  }
  .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-bottom: 20px;
  }

  .dots span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .dots span:first-child {
    background-color: #00a8ff;
  }

  .dots span:nth-child(2) {
    background-color: #9c88ffff;
  }

  .dots span:nth-child(3) {
    background-color: #fbc531ff;
  }

  .dots span:nth-child(4) {
    background-color: #4cd137ff;
  }

  .dots span:nth-child(5) {
    background-color: #487eb0ff;
  }

  .dots span:nth-child(6) {
    background-color: #e84118ff;
  }

  .dots span:nth-child(7) {
    background-color: #8c7ae6ff;
  }

  width: 100%;
  margin: 0 auto;
  text-align: center;
  background-color: #1e0b56 !important;
  h2 {
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 15px;
    color: #ffffff;
  }
  p {
    line-height: 2;
    font-size: 16px;
    line-break: auto;
    margin: 0 auto;
  }
}
.item-container {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 90vw;
  margin: 0 auto;

  @include breakpoints(tablet) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  & .item-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 15px;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  & .column {
    flex: 2;
    @media (min-width: 576px) {
      flex: 4;
    }

    @media (min-width: 768px) {
      flex: 3; // Equivalent to col-md-3
    }

    @media (min-width: 992px) {
      flex: 2; // Equivalent to col-lg-2
    }
    & .trust-item {
      background-color: #fff;
      border-radius: 100px;
      margin-bottom: 40px;
      padding: 75px 15px;
      min-height: 190px;
      padding-bottom: 25px;
      box-shadow: 4px 4px 10px 0 rgb(168 67 253 / 30%);
      overflow: hidden;
      border-bottom: 3px solid #a843fd;

      @include breakpoints(tablet) {
        margin-bottom: 0px;
      }

      & .ico-platform-logo {
        margin-bottom: 25px;
        max-height: 75px;
      }
      & .check {
        height: 40px;
        margin: 0 10px;
        background-color: rgba(13, 0, 59, 0.9);
        border-radius: 5px;
        color: #25cbd3;
        position: relative;

        & .value {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 24px;
          font-weight: 600;
          text-shadow: 0 0 5px rgba(0, 243, 255, 0.5);
        }

        & .check-icon {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: url(https://digitrader.netlify.app/img/svg/checkmark.svg)
            50% no-repeat;
          background-size: contain;
          width: 31px;
          height: 23px;
        }
      }
    }
  }
}
