@import "../responsive/breakpoints";
@import "../abstract/variables";
@mixin scrolling {
  &::-webkit-scrollbar {
    width: 4px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: $bg-white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #dcdada;
  }
}
@mixin dropdown {
  position: absolute;
  background: $bg-white;
  box-shadow: $box-shadow;
  z-index: 99999;
  border: $border-1;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}
.ss-trade_header_wrapper {
  background-color: $bg-white;
  position: sticky;
  top: 0;
  padding: 33px 0;
  right: 0;
  width: 100%;
  bottom: 0;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  border-bottom: $border-header;
  @include breakpoints(tablet) {
    padding: 11px 0;
  }
  .ss-trade_header_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ss-trade_hamburger {
      display: flex;
      align-items: center;
      @include breakpoints(tablet) {
        flex-direction: row-reverse;
        width: 55%;
        justify-content: space-between;
      }
      @include breakpoints(mdlgMobile) {
        flex-direction: row-reverse;
        width: 65%;
        justify-content: space-between;
      }

      .ss-trade_loog {
        width: 100px;
        height: 90px;
        margin-right: 10px;
        display: none;
        @include breakpoints(tablet) {
          width: 100px;
          height: 90px;
          justify-self: center;
          display: block;
        }
      }
      .hamburger_icon {
        font-size: 20px;
        cursor: pointer;
        margin-right: 60px;
        color: $text-navicon-color;
        box-shadow: $box-shadow-navicon;
        padding: 10px;
        border-radius: 50%;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $bg-navicon;
        }
        @include breakpoints(laptop) {
          display: none;
        }
        @include breakpoints(tablet) {
          display: block;
          margin-right: 0px;
        }
        @include breakpoints(tablet) {
          border: $border-header;
        }
      }
      .ss-trade_searchbar {
        position: relative;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          display: none;
        }
        .ss-trade_search_input {
          background: $bg-white;
          border: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 5px 30px;
          font-size: 14px;
          height: 34px;
          outline: none;
          border-left: $border-4;
          border-top: $border-4;
          border-bottom: $border-4;
          color: $text-navicon-color;
          transition: all 0.3s ease-in-out;
        }
        span {
          svg {
            position: absolute;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            background: $bg-white;
            padding: 5px 20px;
            height: 34px;
            top: 0;
            color: $text-navicon-color;
            border-bottom: $border-4;
            border-top: $border-4;
            border-right: $border-4;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    .verticalDots {
      display: none;
      opacity: 0;
      @include breakpoints(tablet) {
        display: block;
        opacity: 1;
      }
      button {
        background-color: $bg-white;
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        box-shadow: $box-shadow-navicon;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          border: $border-header;
        }
        &:hover {
          background: $bg-navicon;
        }
        @include breakpoints(tablet) {
          width: 40px;
          height: 40px;
        }
        svg {
          font-size: 1.2rem;
          color: $text-navicon-color;
        }
      }
    }
    .active_show {
      transform: scaleY(1) !important;
      transform-origin: top;
      transition: all 0.3s ease-in-out;
    }
    .ss-trade_header_menu {
      @include breakpoints(tablet) {
        position: absolute;
        right: 0px;
        top: 85px;
        background: transparent;
        width: 100%;
        padding: 5px 0px;
        z-index: 999;
        padding-right: 22px;
        // box-shadow: 0 12px 11px -3px hsl(212deg 8% 45% / 10%);
        transition: all 0.3s ease-in-out;
        transform: scaleY(0);
        transform-origin: top;
      }
      .ss-trade_header_menu_lists {
        list-style: none;
        display: flex;
        align-items: center;
        @include breakpoints(tablet) {
          justify-content: flex-end;
        }
        .ss-trade_header_menu_list {
          display: inline-block;
          margin-left: 12px;
          position: relative;
          @include breakpoints(tablet) {
            margin-left: 15px;
          }
          .theme_icon {
            button {
              background: $bg-white !important;
              box-shadow: $box-shadow-navicon;
              transition: all 0.3s ease-in-out;
              border: none;
              &:hover {
                background: $bg-navicon !important;
              }
              @include breakpoints(tablet) {
                border: $border-header;
              }
            }
          }
          .msg_icon {
            button {
              background-color: $bg-white;
              position: relative;
              width: 40px;
              height: 40px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;
              cursor: pointer;
              box-shadow: $box-shadow-navicon;
              transition: all 0.3s ease-in-out;
              @include breakpoints(tablet) {
                border: $border-header;
              }
              &:hover {
                background: $bg-navicon;
              }
              @include breakpoints(tablet) {
                width: 40px;
                height: 40px;
              }
              svg {
                font-size: 1.2rem;
                color: $text-navicon-color;
              }
            }
          }
          // chating box
          .chatbox {
            position: relative;
            @include dropdown();
            @include scrolling();
            @include breakpoints(tablet) {
              right: -112px;
            }
            ul {
              list-style: none;
              .header {
                border-bottom: $border-header;
                h4 {
                  padding: 20px;
                  color: $text-p-color;
                  text-transform: capitalize;
                }
              }
              li {
                display: flex;
                padding: 10px;
                align-items: center;
                cursor: pointer;
                border-bottom: $border-header;
                &:last-child {
                  border: none;
                }
                &:hover {
                  background-color: $bg-sidebar-hover;
                }
                &:nth-child(2n) {
                  .image {
                    svg {
                      background: $bg-danger-linear-gradient;
                      box-shadow: $box-shadow-danger;
                    }
                  }
                }
                .image {
                  margin-right: 10px;
                  svg {
                    font-size: 20px;
                    color: #fff;
                    background: $bg-cyan-linear-gradient;
                    box-shadow: $box-shadow-cyan;
                    padding: 12px;
                    border-radius: 50px;
                  }
                }

                .chating_content {
                  h2 {
                    font-size: 13px !important;
                    margin-bottom: 8px !important;
                  }
                  p {
                    font-size: 12px !important;
                    color: #76839a;
                  }
                }
              }
            }
          }
          // notification css
          // .notification {
          //   position: relative;
          //   @include dropdown();
          //   @include scrolling();
          //   @include breakpoints(tablet) {
          //     right: -58px;
          //   }
          //   ul {
          //     list-style: none;
          //     .header {
          //       border-bottom: $border-header;
          //       h4 {
          //         padding: 20px;
          //         color: $text-default-color;
          //         text-transform: capitalize;
          //       }
          //     }
          //     li {
          //       display: flex;
          //       padding: 10px;
          //       align-items: center;
          //       cursor: pointer;
          //       border-bottom: $border-header;
          //       &:last-child {
          //         border: none;
          //       }
          //       &:hover {
          //         background-color: $bg-sidebar-hover;
          //       }
          //       .image {
          //         margin-right: 10px;
          //         svg {
          //           font-size: 20px;
          //           color: #fff;
          //           background: $bg-success-linear-gradient;
          //           box-shadow: $box-shadow-success;
          //           padding: 12px;
          //           border-radius: 50px;
          //         }
          //       }

          //       .notification_content {
          //         h2 {
          //           font-size: 13px;
          //           margin-bottom: 8px !important;
          //         }
          //         p {
          //           font-size: 12px;
          //           color: #76839a;
          //         }
          //       }
          //     }
          //   }
          // }
          .backdrop {
            @include breakpoints(largeMobile) {
              background: rgba(0, 0, 0, 0.18) !important;
            }
          }

          img {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            cursor: pointer;
            box-shadow: $box-shadow-navicon;
            transition: all 0.3s ease-in-out;
            @include breakpoints(tablet) {
              width: 40px;
              height: 40px;
            }
            @include breakpoints(largeMobile) {
              border: $border-header;
            }
          }
          .clickable_submenu {
            @include dropdown();
            width: 180px;
            height: auto;
            .submenu {
              list-style: none;
              .header {
                border-bottom: $border-header;
                display: flex;
                align-items: center;
                padding: 0px 10px;
                .img {
                  margin-right: 10px;
                  img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50px;
                  }
                }
                .name {
                  h4 {
                    color: $text-p-color;
                    padding-top: 5px;
                    text-transform: capitalize;
                    font-size: 14px;
                    // text-align: center;
                  }
                  p {
                    color: $text-p-color;
                    font-size: 13px;
                    // text-align: center;
                    padding-bottom: 10px;
                    text-transform: capitalize;
                  }
                }
              }
              .submenu_list {
                border-bottom: $border-header;
                &:last-child {
                  border: none;
                }
                .submenu_link {
                  color: $text-p-color;
                  display: flex;
                  align-items: center;
                  padding: 5px 20px;
                  text-transform: capitalize;
                  font-size: 13px;
                  transition: all 0.3s ease-in-out;
                  font-weight: 500;
                  padding: 10px;
                  align-items: center;
                  cursor: pointer;
                  &:hover {
                    background-color: $bg-sidebar-hover;
                    color: $text-primary;
                  }
                }
              }
              .submenu_list_static {
                border-bottom: $border-header;
                padding: 5px 10px;
                h4 {
                  color: $text-p-color;
                  text-transform: capitalize;
                  font-size: 13px;
                  // text-align: center;
                }
                p {
                  color: $text-p-color;
                  font-size: 12px;
                  // text-align: center;
                  // padding-bottom: 10px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
}
