@import "../../responsive/breakpoints";

// plan hero section
.ss-trade_front_youtube_hero_wrapper {
  background: linear-gradient(#0e0529, #1e0b56);
  padding-top: 100px;
  padding-bottom: 80px;
  @include breakpoints(tablet) {
    padding-bottom: 20px;
  }
  .videoContainer {
    height: 80vh;
    @include breakpoints(tablet) {
      height: 30vh;
    }
    overflow: hidden;
    .ss-trade_front_youtube_hero_container {
      width: 100%;
      height: 80vh;
      @include breakpoints(tablet) {
        height: 30vh;
      }
      .all_pdf_title {
        width: 100%;
        background: rgba(36, 35, 35, 0);
        padding: 10px 10px;
        form {
          margin: 30px 0px;
          span {
            margin-top: 3px;
          }
        }
      }
      .video_container {
        width: 90%;
        margin: 0px auto;
        height: 80vh;
        iframe {
          border: none;
        }
        @include breakpoints(tablet) {
          height: 30vh;
        }
        .classDocViewer {
          width: "50%";
          height: "100%";
          margin: "0px auto";
        }
      }
    }
  }
}
