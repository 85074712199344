.gallery_body {
  background: linear-gradient(#0e0529, #1e0b56);
}
.gallery_wraper {
  padding-top: 5rem;
  h3 {
    padding: 0.5rem;
    text-align: center;
    font-size: 2rem;
    color: white;
  }
}
.gallery_container {
  padding: 0rem 2rem;
  min-height: 650px;
  display: flex;
  flex-wrap: wrap;
  gap: 2.8rem;
  align-items: center;
  @include breakpoints(xtraLargeLaptop){
    justify-content: start;
  }
  @include breakpoints(semiMiniTablet){
    justify-content: center;
  }
  align-content: center;
  align-self: center;
  justify-content: center;
}
.imgGallery_button_container{
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  align-content: center;
  button{
background: inherit;
border: 1px solid wheat;
color: #ffffff;
padding: 1rem;
outline: none;
cursor: pointer;
  }
}