@import "../../responsive/breakpoints";
.ss-trade_frontpage_wrapper {
  background-color: #1e0b56 !important;
  padding-top: 100px;
  .ss-trade_front_hero_banner_section {
    font-family: $font-family;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    @include breakpoints(tablet) {
      display: inherit;
    }
    .ss-trade_front_hero_banner_content {
      width: 50%;
      @include breakpoints(tablet) {
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
      }
      h2 {
        color: #fff;
        font-size: 50px;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 65px;
        @include breakpoints(tablet) {
          font-size: 35px;
          line-height: inherit;
        }
        @media screen and (max-width: 490px) {
          font-size: 25px;
        }
      }
      p {
        color: #fff;
        font-size: 16px;
        margin: 20px 0 !important;
        margin-bottom: 40px !important;
        font-weight: 600;
        @include breakpoints(tablet) {
          font-size: 13px;
        }
      }
      a {
        padding: 12px 35px !important;
        transition: all 0.3s ease-in-out;
        background-color: #e8c743;
        border-radius: 2px;
        color: #7b5eea !important;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
        &:hover {
          color: #fff !important;
          background-color: #c4a50c;
          border-bottom: none !important;
        }
      }
    }
    .ss-trade_front_hero_banner_img {
      width: 50%;
      @include breakpoints(tablet) {
        width: 100%;
        text-align: center;
      }
      img {
        -webkit-animation: bounce 1.8s ease-in-out 0s infinite alternate;
        animation: bounce 1.8s ease-in-out 0s infinite alternate;
        @include breakpoints(tablet) {
          width: 400px;
          max-width: 100%;
        }
      }
    }
  }
  .skew_divider {
    width: 100%;
    height: 100px;
    left: 0;
    background: linear-gradient(to right bottom, #1e0b56 49%, #0f052c 50%),
      linear-gradient(-50deg, #fff 16px, #000 0);
  }
}
