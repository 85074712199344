.ss-trade_updatewallet_page_wrapper {
  margin-top: 20px;
  .ss-trade_updatewallet_card {
    padding: 25px;
    min-height: 250px;
    .ss-trade_updatewallet_title {
      text-align: start;
      margin-bottom: 20px;
      h2 {
        font-size: 18px;
        text-transform: capitalize;
        color: $text-p-color;
        transition: all 0.3s ease-in-out;
      }
    }
    .ss-trade_updatewallet_field {
      form {
        .form_group {
          margin: 15px 0;
          .input_group {
            display: flex;
            flex-wrap: wrap;
            label {
              width: 100%;
              font-weight: 600;
              font-size: 13px;
              margin-bottom: 10px !important;
              color: $text-p-color;
              transition: all 0.3s ease-in-out;
            }
            input {
              width: 100%;
              padding: 10px 8px;
              border: $border-header;
              outline: none;
              border-radius: 5px;
              color: $text-p-color;
              background: $bg-body;
            }
          }

          .wallet-radio-otp-section {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form_group_OTP {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 5px;
            }
          }
        }
        button {
          // margin-top: 20px;
          width: 130px;
          padding: 10px 8px;
          background: $bg-thin-green;
          box-shadow: $box-shadow-sidebar-menu;
          color: #fff;
          font-size: 14px;
          font-weight: 500;
          border: none;
          text-transform: capitalize;
          border-radius: 5px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
          @include breakpoints(portraitTablet) {
            width: 100%;
          }
        }
      }
    }
  }
  // for option selecting rario button

  .radio-group {
    label {
      display: block;
      margin-bottom: 8px;
      font-weight: bold;
    }

    input[type="radio"] {
      margin-right: 5px;
      vertical-align: middle;
      transform: scale(1.5);
    }

    label {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      cursor: pointer;

      &:hover {
        color: #336699;
      }
    }

    input[type="radio"]:checked + label {
      font-weight: bold;
      color: #336699;
    }
  }
}

.input_amount {
  display: flex;
  flex-wrap: wrap;
  label {
    width: 100%;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 10px !important;
    color: $text-p-color;
    transition: all 0.3s ease-in-out;
  }
  .input-symbol-euro {
    position: relative !important;
    input {
      padding: 10px 18px !important;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='16px' width='85px'><text x='2' y='13' fill='gray' font-size='12' font-family='arial'>$</text></svg>");
      padding-left: 12px;
    }
    // &:before {
    //   position: absolute !important;
    //   top: 0;
    //   content: "$%%%%";
    //   left: 5px;
    //   z-index: 99;
    // }
  }
}
