@mixin title {
  text-align: start;
  margin-bottom: 20px;
  h2 {
    font-size: 18px;
    text-transform: capitalize;
    color: $text-p-color;
    transition: all 0.3s ease-in-out;
  }
}
@mixin card {
  padding: 25px;
  min-height: 250px;
}
@mixin form {
  .form_group {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    @include breakpoints(portraitTablet) {
      display: inherit;
      margin: 0px;
    }
    span {
      color: $text-p-color;
      transition: all 0.3s ease-in-out;
      strong {
        color: $text-primary;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }
    }
    .left {
      margin-right: 33px !important;
      @include breakpoints(portraitTablet) {
        margin-right: 16px !important;
      }
    }
    .right {
      margin-right: 15px !important;
    }
    .input_group {
      width: 50%;
      @include breakpoints(portraitTablet) {
        width: auto;
        margin: 10px 0;
      }
      label {
        width: 100%;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 10px !important;
        color: $text-p-color;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          font-size: 14px;
        }
      }
      input {
        width: 100%;
        padding: 10px 8px;
        border: $border-header;
        outline: none;
        border-radius: 5px;
        color: $text-p-color;
        background: $bg-body;
        margin-top: 10px;
      }
      input:disabled {
        background-color: $bg-body;
        border: none;
        cursor: no-drop;
      }
    }
    .generate_password {
      margin-left: 20px;
      @include breakpoints(portraitTablet) {
        margin-left: 0px;
      }
    }

    button {
      width: 130px;
      padding: 10px 8px;
      height: max-content;
      background: $bg-thin-green;
      box-shadow: $box-shadow-sidebar-menu;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border: none;
      text-transform: capitalize;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      @include breakpoints(portraitTablet) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .OTP_getting_button {
      width: 130px;
      padding: 10px 8px;
      background: $bg-thin-green;
      box-shadow: $box-shadow-sidebar-menu;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border: none;
      text-transform: capitalize;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      @include breakpoints(portraitTablet) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .OTP_getting_resend_button {
      width: 90px;
      height: 17px;
      padding: 10px 8px;
      justify-self: end;
      align-self: end;
      // @include breakpoints(portraitTablet) {
      //   margin-bottom: 25px;
      // }
    }
  }
  .otp_form_group {
    @include breakpoints(portraitTablet) {
      margin-bottom: 25px;
    }
  }
  .show_password {
    display: flex !important;
    align-items: center;
    flex-direction: row;

    .form-check-input {
      display: inline;
    }

    .input_group {
      width: auto !important;
    }
  }
  .form_group_OTP {
    // background-color: red;
    width: 50%;
    @include breakpoints(portraitTablet) {
      width: 100%;
    }
    display: grid;
    grid-template-columns: 7fr 3fr;
    @include breakpoints(mdlgMobile) {
      grid-template-columns: 3fr 2fr;
    }
    gap: 5px;
    .OTP_input_field {
      width: 167% !important;
      @include breakpoints(4kdevice) {
        width: 190% !important;
      }
      @include breakpoints(xtraLargeLaptop) {
        width: 189% !important;
      }
      @include breakpoints(largeLaptop) {
        width: 187% !important;
      }
      @include breakpoints(semiLargeLaptop) {
        width: 185% !important;
      }
      @include breakpoints(laptop) {
        width: 180% !important;
      }
      @include breakpoints(smLaptop) {
        width: 175% !important;
      }
      @include breakpoints(semiMiniTablet) {
        width: 173% !important;
      }
      @include breakpoints(semiTablet) {
        width: 171% !important;
      }
      @include breakpoints(miniSemiTablet) {
        width: 169% !important;
      }
      @include breakpoints(tablet) {
        width: 164% !important;
      }
      @include breakpoints(portraitTablet) {
        width: 100% !important;
      }
    }
    .OTP_resend_btn {
      background: $bg-thin-green;
      box-shadow: $box-shadow-sidebar-menu;
      padding: 0px 12px;
      margin-top: -1px;
      justify-self: end;
      height: 36px;
      align-self: flex-end;
      white-space: nowrap;
      border-radius: 3px;
      border: none;
      color: $text-white;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 1px !important;
      transition: all 0.3s ease-in-out;
      @include breakpoints(portraitTablet) {
        font-size: 12px;
        margin-bottom: 11px !important;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.ss-trade_updatepassword_page_wrapper {
  margin-top: 20px;
  .ss-trade_accountpassword_card {
    @include card();
    .ss-trade_accountpassword_title {
      @include title();
    }
    .ss-trade_accountpassword_field {
      div {
        .form_group {
          margin: 15px 0;
          position: relative;
          .input_group {
            position: relative;
          }
          span {
            position: absolute;
            top: 32px;
            right: 8px;
            z-index: 999;
            font-size: 20px;
            svg {
              color: $text-p-color;
            }
          }
          .right {
            margin-right: 19px;
          }
          label {
            color: $text-p-color;
            font-size: 15px;
            font-weight: 600;
            @include breakpoints(tablet) {
              font-size: 14px;
            }

            span {
              position: inherit;
              font-size: 15px;
              font-weight: 600;
              @include breakpoints(tablet) {
                font-size: 14px;
              }
            }
          }
          .input_field {
            width: 100%;
            padding: 10px 8px;
            border: none;
            outline: none;
            border-radius: 3px;
            background-color: rgba(123, 94, 234, 0.08);
            color: #9fa4a6;
            &:focus {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
              &::placeholder {
                color: #fff;
              }
            }
            &:active {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
            }
            &::placeholder {
              text-transform: uppercase;
              color: #9fa4a6;
              font-size: 11px;
            }
          }
          .OTP_input_field {
            width: 93% !important;
            overflow-y: hidden;
            @include breakpoints(mdlgMobile) {
              width: 90% !important;
            }
          }
          input:-webkit-autofill {
            background-color: rgba(123, 94, 234, 0.7) !important;
          }
        }
        .captchaContainer {
          display: flex;
          align-items: center;
          // justify-content: center;
          p {
            color: #fff;
            padding: 5px;
            font-size: 18px;
            min-width: 18px;
          }
          input {
            color: #fff;
            padding-left: 10px;
            width: 10%;
            font-size: 18px;
            padding-top: 2px;
            padding-bottom: 2px;
            border: 1px solid #ccc;
            outline: none;
            background-color: rgba(123, 94, 234, 0.08);
            &:focus {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
              &::placeholder {
                color: #fff;
              }
            }
            &:active {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
            }
          }
          span {
            // cursor: pointer;
            // padding-left: 10px;
            cursor: pointer;
            svg {
              margin: 4px 0 0px 5px;
              padding: 5px;
              font-size: 18px;
              transform-origin: center center;
              color: rgba(0, 0, 0, 0.2);
              transition: transform 0.2s ease-in, color 0.2s ease-in;
              &:hover {
                color: rgba(0, 0, 0, 0.4);
                transform: rotate(180deg);
              }
            }
          }
        }
        .form_group_OTP {
          // background: red;
          width: 100%;
          display: grid;
          grid-template-columns: 7fr 3fr;
          @include breakpoints(mdlgMobile) {
            grid-template-columns: 3fr 2fr;
          }
          gap: 5px;
          .OTP_resend_btn {
            background-color: #7b5eea;
            // padding: 8px 12px;
            height: 36px;
            align-self: flex-end;
            white-space: nowrap;
            border-radius: 3px;
            border: none;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            @include breakpoints(mdlgMobile) {
              font-size: 12px;
            }
            &:hover {
              background-color: #7b5eeaa6;
              box-shadow: 0 5px 5px 0 rgb(82 63 105 / 5%);
            }
          }
        }
        .form_group_OTP_email {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          .next_btn {
            background-color: #7b5eea;
            height: 36px;
            align-self: flex-end;
            white-space: nowrap;
            border-radius: 3px;
            border: none;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            @include breakpoints(mdlgMobile) {
              font-size: 12px;
            }
            &:hover {
              background-color: #7b5eeaa6;
              box-shadow: 0 5px 5px 0 rgb(82 63 105 / 5%);
            }
          }
          .current_email_otp_next_btn {
            width: 60px;
          }
          .current_email_otp_box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .current_email_otp_resend_btn {
              width: 110px;
            }
          }

          .OTP_resend_btn {
            background-color: #7b5eea;
            // padding: 8px 12px;
            height: 36px;
            align-self: flex-end;
            white-space: nowrap;
            border-radius: 3px;
            border: none;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            @include breakpoints(mdlgMobile) {
              font-size: 12px;
            }
            &:hover {
              background-color: #7b5eeaa6;
              box-shadow: 0 5px 5px 0 rgb(82 63 105 / 5%);
            }
          }
        }

        .submit_btn {
          background-color: #00a5ec;
          padding: 10px 12px;
          width: 100%;
          border-radius: 3px;
          border: none;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          margin-top: 10px !important;
          &:hover {
            background-color: #0183bb;
            box-shadow: 0 5px 5px 0 rgb(82 63 105 / 5%);
          }
        }
        .get_OTP_btn {
          width: 150px;
        }
        .new_email {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 10px;
        }
        .forget_password {
          margin: 10px 0;
          text-align: end;
          a {
            color: #7a5eeaf8;
            font-size: 14px;
            justify-content: center;
            font-weight: 600;
          }
        }
        .go_to_register {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 15px;
          text-align: center;
          p {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            padding: 0px 5px;
            a {
              color: #7a5eeaf8 !important;
            }
            .log_page_nav_link {
              &:hover {
                border-bottom: 2px solid #7b5eea;
                color: #7b5eea;
              }
            }
          }
          .login_nav_break_point {
            color: #7a5eeaf8 !important;
          }
        }
        .go_to_termscondition {
          margin-top: 30px;
          text-align: center;
          p {
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            a {
              color: #7a5eeaf8 !important;
            }
          }
        }
      }
    }
  }
}
